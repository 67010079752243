import {useScrollPosition} from "../../../common/use-scroll-position.hook";
import clsx from "clsx";
import styles from './navbar.module.sass'
import {Link} from 'react-scroll'


export function NavbarComponent() {
    const relativePosition = useScrollPosition() / window.innerHeight
    const isSolid = relativePosition > 0.1

    return <div className={clsx(styles.navbar, isSolid && styles.navbarScrolled)}>
        <Link to={'section-1'} duration={1000} smooth><p>Start</p></Link>

        <Link to={'section-2'} duration={1000} smooth><p>Jak projektujemy</p></Link>
        <Link to={'section-3'} duration={1000} smooth><p>Jak produkujemy</p></Link>
        <Link to={'section-4'} duration={1000} smooth><p>Jak dbamy o jakość</p></Link>
        <Link to={'section-5'} duration={1000} smooth><p>O nas</p></Link>
    </div>
}