import React from 'react';
import {Route, Routes} from 'react-router-dom'
import {Main} from "./pages/main/main";
import {Modules} from "./pages/modules/modules";

function App() {

    return (
        <Routes>
            <Route path='/' element={<Main/>}/>
            <Route path='/moduly' element={<Modules/>}/>
        </Routes>
    );
}

export default App;
