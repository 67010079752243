import styles from "./modules.module.sass";
import React from "react";
import {NavbarComponent} from "./navbar/navbar.component";
import {Element} from "react-scroll";


export interface DisplayModule {
    image: string;
    name: string;
    url: string;
}

const modules: DisplayModule[] = [
    {
        name: 'Moduł pomiaru prądu',
        image: 'moduł pomiaru prądów',
        url: 'Moduł pomiaru prądu MPP-CAN_DTR'
    },
    {
        name: 'Moduł pomiaru ciśnienia wodoru',
        image: 'moduł ciśnienie wodoru',
        url: 'Moduł RYMI - pomiar ciśnienia wodoru_DTR'
    },
    {
        name: 'Moduł rozdzielacza impulsów drogi',
        image: 'Rozdzielacz impulsów drogi',
        url: 'Rozdzielacz impulsów drogi_DTR'
    },
    {
        name: 'Moduł przekaźnikowy',
        image: 'moduł przekaźnikowy',
        url: 'Moduł przekaźnikowy CANuPM-14_DTR'
    },
    {
        name: 'Moduł włączników prądu',
        image: 'mod_can',
        url: 'Moduł RYMI MOD_CAN_28OUT_1A_DTR'
    },
    {
        name: 'Moduł generatora dźwięku',
        image: 'generator dźwięku',
        url: 'Generator Dźwięku 2 RYMI_DTR'
    },
    {
        name: 'Moduł czujnika przechyłu',
        image: 'czujnik przechyłu',
        url: 'Moduł przechyłomierza_DTR'
    }
]

export function Modules() {
    return <>
        <NavbarComponent modules={modules}/>
        {
            modules.map((module, i) => {
                return <Element key={module.name} className={i % 2 ? styles.screenOdd : styles.screenEven} name={module.name}>
                    <img src={`${process.env.PUBLIC_URL}/modules/${module.image}_bot.png`} className={styles.image}/>
                    <img src={`${process.env.PUBLIC_URL}/modules/${module.image}_top.png`} className={styles.imageTop}/>
                    <p className={styles.title}>{module.name}</p>
                    <a className={styles.description} target="_blank" rel="noopener noreferrer" href={`${process.env.PUBLIC_URL}/modules_doc/${module.url}.pdf`}>Zobacz dane techniczne</a>
                </Element>
            })
        }

    </>
}