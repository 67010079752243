import clsx from "clsx";
import styles from './navbar.module.sass'
import {Link} from 'react-scroll'
import {Link as UrlLink} from 'react-router-dom'
import {DisplayModule} from "../modules";

interface Props {
    modules: DisplayModule[]
}

export function NavbarComponent({modules}: Props) {
    return <div className={clsx(styles.navbar)}>
        <UrlLink onClick={()=> window.scrollTo(0,0)} to={'/'}><p>Start</p></UrlLink>
        {modules.map(module => {
            return <Link to={module.name} key={module.name} duration={1000} smooth><p>{module.name}</p></Link>
        })}

    </div>
}