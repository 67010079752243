import {useEffect, useState} from 'react'


export function useScrollPosition() {
    const [currentPosition, setCurrentPosition] = useState(window.scrollY)

    const callBack = () => {
        setCurrentPosition(window.scrollY)
    }

    useEffect(() => {
        window.addEventListener('scroll', callBack)
        return () => {
            window.removeEventListener('scroll', callBack)
        }
    }, [])
    return currentPosition
}
