import styles from "./main.module.sass";
import React from "react";
import {NavbarComponent} from "./navbar/navbar.component";
import {Element, Link} from "react-scroll";
import {MailIcon, PhoneIcon} from "@heroicons/react/solid";

export function Main() {
    return <>
        <NavbarComponent/>
        <Element className={styles.screenOne} name='section-1'>
            <div className={styles.screenOneBackground}></div>
            <div style={{
                position: "absolute",
                top: `21.88%`,
                height: `16.4%`,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                pointerEvents: "none"
            }}>
                <p style={{
                    fontSize: '8em',
                    color: '#F8F8F8',
                    letterSpacing: '0.35em',
                    marginBottom: 0,
                    marginTop: 0,
                    marginRight: '-0.35em',
                }}>RYMI</p>
            </div>
            <div style={{
                position: "absolute",
                top: '34.25%',
                margin: 0,
                height: `9.9%`,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}>
                <p style={{
                    fontSize: `2.5em`,
                    color: '#F8F8F8',
                    letterSpacing: '0.1em',
                    margin: 0,
                }}>Innowacje
                    Elektroniczne</p>
            </div>
            <div style={{
                height: '32%',
                position: "absolute",
                top: '40%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}>
                <p style={{
                    fontSize: `2em`,
                    color: '#F8F8F8',
                    textAlign: 'center'
                }}>
                    Oferujemy projektowanie urządzeń elektronicznych<br/>
                    razem z ich nisko i średnio seryjną produkcją.<br/>
                    Aktualnie działamy głównie w branży motoryzacyjnej jako OEM.
                </p>
            </div>
            <Link to={'section-2'}  smooth duration={1000} style={{
                top: '75%',
                position: "absolute",
                height: `9.25%`,
                width: '21.5%',
                borderRadius: `1em`,
                background: '#4D8C57',
                border: "none",
                display: 'flex',
                justifyContent: "center",
                alignItems: "center",
                cursor:'pointer'
            }}>
                <div style={{
                    fontSize: '2em',
                    color: '#F8F8F8',
                    letterSpacing: '0.1em',
                    userSelect: 'none'
                }}>Zobacz więcej
                </div>
            </Link>
        </Element>
        <Element className={styles.screenTwo} name='section-2'>
            <img className={styles.image} style={{top: '23.9%', left: '5.7%'}}
                 src={`${process.env.PUBLIC_URL}/MicrochipStudio.png`}/>
            <img className={styles.imageFade} style={{top: '23.9%', left: '5.7%'}}
                 src={`${process.env.PUBLIC_URL}/Eagle(1).png`}/>
            <p style={{
                fontSize: "3.5em",
                letterSpacing: "0.1em",
                position: 'absolute',
                top: "33.3%",
                marginTop:0,
                left: "58.3%",
                fontWeight: "600"
            }}>Jak projektujemy</p>
            <p style={{
                fontSize: "1.5em",
                letterSpacing: "0.1em",
                position: 'absolute',
                top: "44.4%",
                marginTop:0,
                left: "58.3%",
                width: '37.5%'
            }}>
                Jeżeli nasz produkt zawiera procesor, to jego program jest zawsze pisany w języku asemblera.<br/>
                Dzięki temu wiemy co i dokładnie w którym momencie zrobi nasz sterownik.<br/>
                Obwody drukowane projektujemy z uwzględnieniem zasad kompatybilności elektromagnetycznej,<br/>
                czyli oddziaływania pól elektrycznych i magnetycznych.<br/>
            </p>
        </Element>
        <Element className={styles.screenThree} name='section-3'>
            <img style={{top: `23.1%`, left: '43.2%', position: 'absolute'}} className={styles.image}
                 src={`${process.env.PUBLIC_URL}/IMG_3150.JPG`}/>
            <p style={{top: "30.3%", left: "4.1%", fontWeight: "600"}} className={styles.screenTitle}>Jak
                produkujemy</p>
            <p style={{top: "40.4%", left: "4.1%", width: '37.5%'}} className={styles.screenDescription}>
                Elementy na obwodach drukowanych układamy wizyjnym automatem montażowym M10V firmy Mechatronika. <br/>
                Stosujemy pastę lutowniczą bezołowiową zawierającą 3% srebra typu OM-338PT firmy Alpha. Lutujemy w piecu
                na gorące powietrze własnej konstrukcji. <br/>
                Do lutowania ręcznego używamy drutu bezołowiowego zawierającego 4% srebra firmy Cynel.<br/>
                Otwory pod złącza w uniwersalnych obudowach plastikowych wycinamy frezarką numeryczną<br/>
            </p>
        </Element>
        <Element className={styles.screenFour} name='section-4'>
            <img className={styles.image} style={{top: '25.9%', left: '5.7%'}}
                 src={`${process.env.PUBLIC_URL}/maszyna3.png`}/>
            <p style={{
                top: "37.3%",
                left: "58.3%",
                fontWeight: "600"
            }} className={styles.screenTitle}>Jak dbamy o jakość</p>
            <p style={{
                top: "48.4%",
                left: "58.3%",
                width: '37.5%'
            }} className={styles.screenDescription}>
                W ciągu 35 lat działalności jako firma wypracowaliśmy własny system zapewnienia jakości. Z reguły dajemy
                3 letnią gwarancję. Firmom zainteresowanym współpracą z nami możemy przedstawić wieloletnie referencje
                od naszych odbiorców.
            </p>
        </Element>
        <Element className={styles.screenFive} name='section-5'>
            <p style={{
                top: "16.6%",
                left: "4%",
                fontWeight: "600"
            }} className={styles.screenTitle}>O nas</p>
            <p style={{
                top: "26.8%",
                left: "4%",
            }} className={styles.screenDescription}>
                Jesteśmy na rynku od 1987 roku, początkowo pod nazwą "Zakład Elektroniczny mgr inż. Ryszard Misiak" z
                siedzibą w Poznaniu.
            </p>
            <p style={{
                top: "34%",
                left: "4%",
                width: "84%"
            }} className={styles.screenDescription}>
                Naszym najbardziej znanym patentem jest
                "<a href={`${process.env.PUBLIC_URL}/patent1.pdf`} target="_blank" rel="noopener noreferrer">Tor
                pośredniej częstotliwości odbiornika, zwłaszcza odbiornika telewizyjnego lub magnetowidowego</a>".
                Na podstawie tego pomysłu rozpoczęliśmy produkcję tak zwanej „płytki fonii {">"}RYMI{"<"}”, służącej do
                przestrajania dźwięku
                odbiorników telewizyjnych z systemu B/G na system D/K. W ciągu ponad 21 lat produkcji wyrób
                stał się znany i ceniony przez serwisy RTV w Polsce i za granicą.
            </p>
            <p style={{
                top: "53.7%",
                left: "4%",
                width: "84%"
            }} className={styles.screenDescription}>
                W 2011 roku uzyskaliśmy patent na "<a href={`${process.env.PUBLIC_URL}/patent2.pdf`} target="_blank"
                                                      rel="noopener noreferrer">Urządzenie, układ i sposób wizualnej
                sygnalizacji intensywności hamowania
                samochodu</a>". Nie doszło do produkcji tego urządzenia (
                <a href='https://www.youtube.com/watch?v=ycTgF3VArgE&feature=youtu.be&ab_channel=WWWrymipl' target="_blank" rel="noopener noreferrer">światło RYMI-STOP</a>
                ) z powodu „niejasności” w Polsce w przyznawaniu homologacji na część samochodową.
            </p>

            <p style={{
                top: "67.1%",
                left: "4%",
                width: "84%"
            }} className={styles.screenDescription}>
                Od tamtego czasu firma RYMI zajmuje się projektowaniem i produkcją urządzeń elektronicznych na
                zamówienie.
                Odbiorcą jest między innymi przemysł motoryzacyjny,
                dla którego są projektowane i produkowane urządzenia będące częściami składowymi autobusów.
            </p>
            <div style={{
                position: "absolute",
                bottom: 0,
                left: '10%',
                right: '5%',
                height: '12.5%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: "center"
            }}>
                <p className={styles.footerText}>
                    RYMI Innowacje Elektroniczne <br/>
                    ul. Dobrowita 28 <br/>
                    62-090 Mrowino
                </p>

                <p className={styles.footerText}>
                    <PhoneIcon style={{height: '1em', marginBottom: '-0.15em'}}/> 61 867 98 90 <br/>
                    <MailIcon style={{height: '1em', marginBottom: '-0.15em'}}/> biuro@rymi.pl
                </p>
                <p className={styles.footerText}>
                    NIP PL7791031726 <br/>
                    REGON 632293286 <br/>
                    KONTO 42 1940 1076 5268 7022 0000 0000
                </p>
            </div>
        </Element>
    </>
}